import * as PropTypes from "prop-types";
import React from "react";
import { ElementShape, QuestionShape, RequirementShape } from "../shapes";
import ElementRow from "./ElementRow/ElementRow";

class PriorityManager extends React.Component {
  static propTypes = {
    elements: PropTypes.arrayOf(ElementShape).isRequired,
    requirements: PropTypes.arrayOf(RequirementShape).isRequired,
    questions: PropTypes.arrayOf(QuestionShape).isRequired
  };

  state = {
    elements: this.props.elements,
    requirements: this.props.requirements,
    questions: this.props.questions
  };

  /**
   * Handles when a `Question` is moved up.
   */
  handleMoveQuestionUp = ({ question }) =>
    this.moveQuestionPriorityWithinParentOnState(question, -1);

  /**
   * Handles when a `Question` is moved down.
   */
  handleMoveQuestionDown = ({ question }) =>
    this.moveQuestionPriorityWithinParentOnState(question, +1);

  /**
   * @inheritDoc
   *
   * @override
   */
  componentDidMount() {
    /*
      Submission is done by mapping each question into an input,
      with the name being the record type & id, & the value being their position.

      The naming structure should result in a `params` containing a hash,
      named `questions`, with the key being the record id, like so:

      {
        "questions" => { "1" => "1", "2" => "2", "3" => "1", ... }
      }
     */
    $("#save-order-form").on("submit", event =>
      $(event.target).append(
        ...this.state.questions.map(question =>
          $("<input />")
            .attr("name", `questions[${question.id}]`)
            .attr(
              "value",
              JSON.stringify({
                priority_within_element: this.getQuestionPriorityWithinElement(
                  question
                ),
                edited: question["edited"]
              })
            )
            .attr("type", "hidden")
        )
      )
    );

    /*
      When `#discard-order-button` is clicked, we reset everything by just
      copying the props to the state, since they should be structurally 1:1.
     */
    $("#discard-order-button").on("click", () =>
      this.setState({ ...this.props })
    );
  }

  /**
   * Gets the `real_priority_within_element` of the given `question`.
   *
   * This returns `real_priority_within_element` if define, otherwise `priority_within_element`.
   * We use this method to make it easy to maintain the visible value of `priority_within_element`,
   * while still updating & sorting by that value.
   *
   * @param {Object} question
   *
   * @return {number}
   *
   * @instance
   */
  getQuestionPriorityWithinElement(question) {
    return (
      question.real_priority_within_element || question.priority_within_element
    );
  }

  /**
   * Gets all the `Question`s that belong to the given `requirement` from the `state`.
   *
   * @param {Object} requirement
   *
   * @return {Array<Object>}
   */
  getQuestionsForRequirementFromState(requirement) {
    const requirementId =
      requirement.parent_id !== null ? requirement.parent_id : requirement.id;

    return this.state.questions.filter(
      question => question.requirement_id === requirementId
    );
  }

  /**
   * Gets all the `Requirement`s that belong to the given `element` from the `state`.
   *
   * @param {Object} element
   *
   * @return {Array<Object>}
   */
  getRequirementsForElementFromState(element) {
    const elementId =
      element.parent_id !== null ? element.parent_id : element.id;

    return this.state.requirements.filter(
      requirement => requirement.element_id === elementId
    );
  }

  /**
   * Gets all the `questions` on the `state` that belong to the given `element`, via their `requirement`.
   *
   * @param {Object} element the `Element` to group `Requirement`s for.
   *
   * @return {Array<Object>}
   */
  getQuestionsForElementFromState(element) {
    return this.getRequirementsForElementFromState(element)
      .map(requirement => this.getQuestionsForRequirementFromState(requirement))
      .reduce((arr, questions) => arr.concat(questions), [])
      .sort(
        (a, b) =>
          this.getQuestionPriorityWithinElement(a) -
          this.getQuestionPriorityWithinElement(b)
      );
  }

  /**
   * Maps `elements` from the `state` into groups containing the `Element` & their
   * `Requirement`s, in turn grouped with their `Question`s.
   *
   * @return {Array<Object>}
   */
  groupElementsFromState() {
    return this.state.elements
      .map(element => ({
        element,
        questions: this.getQuestionsForElementFromState(element)
      }))
      .sort(
        (a, b) => a.element.order_within_parent - b.element.order_within_parent
      );
  }

  /**
   * Moves the given `question` by the given `amount` in the `questions` collection on the state.
   *
   * This methods causes the recalculation of the `priority` of *all* `Question`s.
   *
   * @param {Object} question
   * @param {number} amount
   */
  moveQuestionPriorityWithinParentOnState(question, amount) {
    const newPosition =
      this.getQuestionPriorityWithinElement(question) + amount;

    if (newPosition <= 0) {
      return;
    }

    const questionRequirement = this.state.requirements.find(requirement => {
      const requirementId =
        requirement.parent_id !== null ? requirement.parent_id : requirement.id;

      return requirementId === question.requirement_id;
    });

    const questionElementId = this.state.elements.find(element => {
      const elementId =
        element.parent_id !== null ? element.parent_id : element.id;

      return elementId === questionRequirement.element_id;
    }).id;

    const groupedElements = this.groupElementsFromState();

    const { questionsToReorder, otherQuestions } = groupedElements.reduce(
      (acc, groupedElement) => {
        // only questions belonging to the same element get sorted
        (groupedElement.element.id === questionElementId
          ? acc.questionsToReorder
          : acc.otherQuestions
        ).push(...groupedElement.questions);

        return acc;
      }, // we need to retain the other questions to concat later
      { questionsToReorder: [], otherQuestions: [] }
    );

    this.setState({
      questions: this.reprioritiseOrderableInCollection(
        question,
        newPosition,
        questionsToReorder
      ).concat(otherQuestions)
    });
  }

  /**
   * Reprioritises the given `orderable` to be have the given `priority_within_element`,
   * recalculating the `priority_within_element` value of all elements in the given `orderables`,
   * to reflect their orders with the `orderable` in its news position.
   *
   * @param {T} orderable
   * @param {number} position
   * @param {Array<T>} orderables
   *
   * @return {Array<T>}
   *
   * @template T
   */
  reprioritiseOrderableInCollection(orderable, position, orderables) {
    orderable["edited"] = true;

    const objects = [...orderables].sort(
      (a, b) =>
        this.getQuestionPriorityWithinElement(a) -
        this.getQuestionPriorityWithinElement(b)
    );

    objects.splice(objects.indexOf(orderable), 1);
    objects.splice(position - 1, 0, orderable);

    return objects.map((orderable, pos) =>
      Object.assign({}, orderable, { real_priority_within_element: pos + 1 })
    );
  }

  render() {
    const groupedElements = this.groupElementsFromState();

    return (
      <div>
        {groupedElements.map(groupedElement => (
          <ElementRow
            key={groupedElement.element.id}
            groupedElement={groupedElement}
            rowsPerHeader={10}
            onMoveQuestionUp={this.handleMoveQuestionUp}
            onMoveQuestionDown={this.handleMoveQuestionDown}
          />
        ))}
      </div>
    );
  }
}

export default PriorityManager;
