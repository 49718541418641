import cx from "classnames";
import * as PropTypes from "prop-types";
import React from "react";

const OrderControls = props => (
  <div className={cx("order-controls", props.className)}>
    <div className="order-controls__move-up" onClick={props.onMoveUpArrowClick} />
    <div className="order-controls__move-down" onClick={props.onMoveDownArrowClick} />
  </div>
);

OrderControls.propTypes = {
  className: PropTypes.string,
  onMoveUpArrowClick: PropTypes.func.isRequired,
  onMoveDownArrowClick: PropTypes.func.isRequired
};

export default OrderControls;
