import cx from "classnames";
import React from "react";
import RequirementRowColumn from "../RequirementRowColumn/RequirementRowColumn";

/**
 * Gets the content to render for the given "column" of this rows `Requirement`.
 *
 * @param {string} columnName
 * @param {string} content
 *
 * @return {JSX}
 */
const getRequirementColumnRenderContent = (columnName, content) => (
  <RequirementRowColumn className={`record-row__column--${columnName}`}>
    {content}
  </RequirementRowColumn>
);

const RequirementHeader = () => (
  <div className={cx("record-row", "record-row__header")}>
    <RequirementRowColumn className={"record-row__column--order-controls"} />
    {getRequirementColumnRenderContent("code", "Code")}
    {getRequirementColumnRenderContent("name", "Name")}
    {getRequirementColumnRenderContent("optional", "Optional")}
    {getRequirementColumnRenderContent("status", "Status")}
    <RequirementRowColumn className={"record-row__column--questions-count"}>
      Number of Questions
    </RequirementRowColumn>
    <RequirementRowColumn className={"record-row__column--expansion-arrow"} />
  </div>
);

export default RequirementHeader;
