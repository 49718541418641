import React from "react"
import PropTypes from "prop-types"
import RespondentRow from "./RespondentRow"

class RespondentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { respondents: [], editMode: {} };
  };

  componentWillMount() {
    this.loadRespondents();
  };

  loadRespondents = () => {
    $.getJSON('/api/respondents.json', (response) => {
      this.setState({ respondents: response });
    });
  };

  resetForms = () => {
    let forms = {};
    this.state.respondents.map((respondent) => {
      forms[respondent.id] = false;
    });
    this.setState({ editMode: forms });
    $(".users-table__button--add").focus();
  };

  setEditMode = (id) => {
    let forms = {};
    this.state.respondents.map((respondent) => {
      forms[respondent.id] = respondent.id == id;
    })
    forms["new"] = id == "new";
    this.setState({ editMode: forms });
  };

  render () {
    let content = <p className="users-table__paragraph">Your business currently has no people, please click the 'Add' button to start.</p>;

    if(this.state.respondents.length > 0 || this.state.editMode["new"]) {
      content = this.state.respondents.map((respondent, index) => {
        return <RespondentRow key={ respondent.id } respondent={ respondent } business_roles={ this.props.business_roles } business_locations={ this.props.business_locations } business_functions={ this.props.business_functions } business_territories={ this.props.business_territories } editMode={ this.state.editMode[respondent.id] } createForm={ false } resetForms={this.resetForms} setEditMode={this.setEditMode} loadRespondents={this.loadRespondents} />;
      });
    }

    return (
            <div className="users-table users-table--spaced">
              <div className="users-table__row">
                <div className="users-table__heading users-table__heading--name"><span className="users-table__text">Name</span></div>
                <div className="users-table__heading users-table__heading--role"><span className="users-table__text">Role</span></div>
                <div className="users-table__heading users-table__heading--location"><span className="users-table__text">Location</span></div>
                <div className="users-table__heading users-table__heading--territory"><span className="users-table__text">Region</span></div>
                <div className="users-table__heading users-table__heading--function"><span className="users-table__text">Team</span></div>
                <div className="users-table__heading users-table__heading--email"><span className="users-table__text">Email</span></div>
                <div className="users-table__heading users-table__heading--mobile"><span className="users-table__text">Mobile</span></div>
                <div className="users-table__heading users-table__heading--comms"><span className="users-table__text">Preference</span></div>
                <div className="users-table__heading users-table__heading--edit"><span className="users-table__text"></span></div>
                <div className="users-table__heading users-table__heading--delete"><span className="users-table__text"></span></div>
              </div>

              <div id="js-respondent-list">
                { content }
              </div>
              <RespondentRow key={ "new" } business_roles={ this.props.business_roles } business_locations={ this.props.business_locations } business_functions={ this.props.business_functions } business_territories={ this.props.business_territories } editMode={ this.state.editMode["new"] } createForm={ true } resetForms={this.resetForms} setEditMode={this.setEditMode} loadRespondents={this.loadRespondents} />
            </div>
    );
  }
}

RespondentTable.propTypes = {
  business_roles: PropTypes.array,
  business_locations: PropTypes.array,
  business_functions: PropTypes.array,
  business_territories: PropTypes.array
};

export default RespondentTable;
