import * as PropTypes from "prop-types";
import React from "react";
import Collapsible from "react-collapsible";
import { GroupedRequirementShape } from "../../shapes";
import RequirementRowDetails from "../RequirementRowDetails/RequirementRowDetails";
import RequirementRowQuestions from "../RequirementRowQuestions/RequirementRowQuestions";

class RequirementRow extends React.Component {
  static propTypes = {
    groupedRequirement: GroupedRequirementShape.isRequired,
    rowsPerHeader: PropTypes.number,

    onMoveRequirementUp: PropTypes.func.isRequired,
    onMoveRequirementDown: PropTypes.func.isRequired,
    onMoveQuestionUp: PropTypes.func.isRequired,
    onMoveQuestionDown: PropTypes.func.isRequired
  };

  static defaultProps = {
    rowsPerHeader: 0
  };

  state = {
    open: false
  };

  /**
   * Handles when the up arrow on the `OrderControls` component is clicked.
   */
  handleMoveUpArrowClick = () => this.props.onMoveRequirementUp({ requirement: this.props.groupedRequirement.requirement });

  /**
   * Handles when the down arrow on the `OrderControls` component is clicked.
   */
  handleMoveDownArrowClick = () => this.props.onMoveRequirementDown({ requirement: this.props.groupedRequirement.requirement });

  /**
   * Handles when the `Collapsible` starts opening.
   */
  handleCollapsibleOpening = () => this.setState({ open: true });

  /**
   * Handles when the `Collapsible` starts closing.
   */
  handleCollapsibleClosing = () => this.setState({ open: false });

  render() {
    return (
      <Collapsible
        trigger={
          <RequirementRowDetails
            requirement={this.props.groupedRequirement.requirement}
            numberOfQuestions={this.props.groupedRequirement.questions.length}

            open={this.state.open}

            onMoveRequirementUp={this.handleMoveUpArrowClick}
            onMoveRequirementDown={this.handleMoveDownArrowClick}
          />
        }

        onOpening={this.handleCollapsibleOpening}
        onClosing={this.handleCollapsibleClosing}
      >
        <RequirementRowQuestions
          questions={this.props.groupedRequirement.questions}
          rowsPerHeader={this.props.rowsPerHeader}

          onMoveQuestionUp={this.props.onMoveQuestionUp}
          onMoveQuestionDown={this.props.onMoveQuestionDown}
        />
      </Collapsible>
    );
  }
}

export default RequirementRow;
