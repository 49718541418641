import cx from "classnames";
import * as PropTypes from "prop-types";
import React from "react";

const ElementRowColumn = props => (
  <div className={cx("record-row__column", props.className)}>
    {props.children}
  </div>
);

ElementRowColumn.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default ElementRowColumn;
