import React from "react"
import PropTypes from "prop-types"

class AssessmentRespondentForm extends React.Component {

  constructor(props) {
    super(props);

    if(props.assessment_respondent) {
      this.state = {
        id: props.assessment_respondent.id,
        name: props.assessment_respondent.name,
        email: props.assessment_respondent.email,
        mobile: props.assessment_respondent.mobile,
        preferred_comms_method: props.assessment_respondent.preferred_comms_method
      };
    }
    else {
      this.state = {
        id: "",
        name: "",
        email: "",
        mobile: "",
        preferred_comms_method: "prefers_email_comms"
      };
    }
  };

  componentDidMount() {
    $("#email").focus()[0].scrollIntoView({block: "center"});
  }

  handleSave = id => {
    var data = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      preferred_comms_method: this.preferredCommsMethodValue()
    };

    if(this.props.assessment_started) {
      this.props.handleConfirmAndSend(id, data);
      return
    }

    this.props.handleConfirm(id, data);
  };

  handleCancel() {
    this.props.handleCancel();
  };

  handleInputChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: value
    });
  };

  validateInput = (element) => {
    const value = element.value;
    const attribute = element.name;

    this.setState({
      [element.name]: value
    });

    let valid = true;
    let message = "";

    switch(attribute) {
      case "email":
        valid = value.length == 0 || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        message = valid ? '' : "Please enter a valid email address";
        break;
      case "mobile":
        valid = value.length == 0 || value.match(/^02\d{7,9}$/i);
        message = valid ? '': "A mobile number must start with 02 and be 9-11 numbers with no spaces or dashes";
        break;
      default:
        break;
    }

    this.props.addValidationError(attribute, message);
  }

  renderInputError = (attribute) => {
    let error;
    if(this.inputErrorPresent(attribute)) {
      error = this.props.errors[attribute][this.props.errors[attribute].length - 1];
      return(
        <label className={ `message form__error ${ attribute }` } htmlFor="name">
          { error }
        </label>
      );
    }
  }

  inputErrorPresent = (attribute) => {
    return (
      this.props.errors[attribute] && this.props.errors[attribute].length
    )
  }

  renderPreferredCommsMethod = (email, mobile, preferredCommsMethod) => {
    const canHavePreference = email && email.length > 0 && mobile && mobile.length > 0;

    if(canHavePreference) {
      return <div className="form__field">
        <div className="radio__group">
          <label htmlFor="preferred_comms_method" className="form__label form__label--small">Preferred Comms</label>
          <div className="radio__control">
            <input type="radio" name="preferred_comms_method" id="preferred_comms_method_email" className="radio__input" value="email" defaultChecked={ preferredCommsMethod == "prefers_email_comms" } onChange={this.handleInputChange} />
            <label htmlFor="preferred_comms_method_email" className="radio__label">Email</label>
          </div>
          <div className="radio__control">
            <input type="radio" name="preferred_comms_method" id="preferred_comms_method_mobile" className="radio__input" value="mobile" defaultChecked={ preferredCommsMethod == "prefers_mobile_comms" } onChange={this.handleInputChange} />
            <label htmlFor="preferred_comms_method_mobile" className="radio__label">Mobile</label>
          </div>
        </div>
      </div>
    }
  };

  preferredCommsMethodValue() {
    if(this.state.email && this.state.mobile)
      return this.state.preferred_comms_method;

    return this.state.email ? "email" : "mobile";
  };

  preferredCommsHeaderValue() {
    if (this.props.assessment_started)
      return `Resend invitation to ${this.state.name}`

    return `Update contact details for ${this.state.name} for this assessment`
  };

  preferredCommsSaveButtonLabel() {
    if (this.props.assessment_started)
      return "Send";

    return "Confirm";
  };

  render() {

    return (
      <div className="users-table__form">
        <div className="users-table__form-section form__section--limited">
          <h4>{ this.preferredCommsHeaderValue() }</h4>

          <div className="form__field">
            <label htmlFor="email" className="form__label form__label--small">Email</label>
            <input type="email" id="email" name="email" className="form__input" data-error={this.inputErrorPresent("email")} defaultValue={ this.state.email } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} placeholder="e.g. admin@betabuilding.co.nz" maxLength="254" />
            { this.renderInputError("email") }
          </div>

          <div className="form__field">
            <label htmlFor="mobile" className="form__label form__label--small">Mobile</label>
            <input type="text" id="mobile" name="mobile" className="form__input" data-error={this.inputErrorPresent("mobile")} defaultValue={ this.state.mobile } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} placeholder="e.g. 02123456789" maxLength="11" />
            { this.renderInputError("mobile") }
          </div>

          { this.renderPreferredCommsMethod(this.state.email, this.state.mobile, this.state.preferred_comms_method) }

          { this.renderInputError("base") }
        </div>
        <div className="form__section form__section--actions">
          <div className="form__button-wrapper">
            <button className="test-cancel-button form__button form__button--cancel form__button--small" onClick={ () => this.handleCancel() }>Cancel</button>
          </div>
          <div className="form__button-wrapper">
            <button className="form__button form__button--submit form__button--black form__button--small" onClick={ () => this.handleSave(this.state.id) }>{ this.preferredCommsSaveButtonLabel() }</button>
          </div>
        </div>
      </div>
    )
  }
}

AssessmentRespondentForm.propTypes = {
  errors: PropTypes.object,
  assessment_started: PropTypes.bool
};

export default AssessmentRespondentForm;
