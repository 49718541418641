import cx from "classnames";
import * as PropTypes from "prop-types";
import React from "react";

const AccordionArrow = props => (
  <div className={cx(`accordion-arrow--${props.colour}`, props.className)}>
    <div
      className={cx(
        props.pointUp
          ? `accordion-arrow--${props.colour}__point-up`
          : `accordion-arrow--${props.colour}__point-down`
      )}
    />
  </div>
);

AccordionArrow.propTypes = {
  className: PropTypes.string,
  pointUp: PropTypes.bool,
  colour: PropTypes.string
};

export default AccordionArrow;
