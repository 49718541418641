import * as PropTypes from "prop-types";
import React from "react";
import { GroupedRequirementShape } from "../../shapes";
import RequirementHeader from "../RequirementHeader/RequirementHeader";
import RequirementRow from "../RequirementRow/RequirementRow";

class ElementRowRequirements extends React.Component {
  static propTypes = {
    groupedRequirements: PropTypes.arrayOf(GroupedRequirementShape).isRequired,
    rowsPerHeader: PropTypes.number,
    onMoveRequirementUp: PropTypes.func.isRequired,
    onMoveRequirementDown: PropTypes.func.isRequired,
    onMoveQuestionUp: PropTypes.func.isRequired,
    onMoveQuestionDown: PropTypes.func.isRequired
  };

  static defaultProps = {
    rowsPerHeader: 0
  };

  /**
   * Checks if a `RequirementHeader` component should be added to the list of `RequirementRow`s,
   * based on the index of the current `Requirement` being rendered, compared with the value of `rowsPerHeader`.
   *
   * @param {number} index
   *
   * @return {boolean}
   */
  shouldAddRequirementRowHeader(index) {
    if (this.props.rowsPerHeader === false) {
      return false;
    }

    if (index === 0) {
      return true;
    }

    return index % this.props.rowsPerHeader === 0;
  }

  render() {
    return (
      <div className="record-row__requirements">
        {
          this.props.groupedRequirements.map((groupedRequirement, index) => [
            this.shouldAddRequirementRowHeader(index) && <RequirementHeader key={`header-${index}`} />,
            <RequirementRow
              key={groupedRequirement.requirement.id}

              groupedRequirement={groupedRequirement}
              rowsPerHeader={10}

              onMoveRequirementUp={this.props.onMoveRequirementUp}
              onMoveRequirementDown={this.props.onMoveRequirementDown}
              onMoveQuestionUp={this.props.onMoveQuestionUp}
              onMoveQuestionDown={this.props.onMoveQuestionDown}
            />
          ])
        }
      </div>
    );
  }
}

export default ElementRowRequirements;
