import * as PropTypes from "prop-types";
import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { GroupedElementShape } from "../../shapes";
import ElementRowDetails from "../../ElementRowDetails";
import ElementRowRequirements from "../ElementRowRequirements/ElementRowRequirements";

class ElementRow extends Component {
  state = {
    open: false
  };

  /**
   * Handles when the `Collapsible` starts opening.
   */
  handleCollapsibleOpening = () => this.setState({ open: true });

  /**
   * Handles when the `Collapsible` starts closing.
   */
  handleCollapsibleClosing = () => this.setState({ open: false });

  render() {
    return (
      <Collapsible
        trigger={
          <ElementRowDetails
            element={this.props.groupedElement.element}

            open={this.state.open}

            onMoveRequirementUp={this.handleMoveUpArrowClick}
            onMoveRequirementDown={this.handleMoveDownArrowClick}
          />
        }

        onOpening={this.handleCollapsibleOpening}
        onClosing={this.handleCollapsibleClosing}
      >
        <ElementRowRequirements
          groupedRequirements={this.props.groupedElement.requirements}
          rowsPerHeader={this.props.rowsPerHeader}

          onMoveRequirementUp={this.props.onMoveRequirementUp}
          onMoveRequirementDown={this.props.onMoveRequirementDown}
          onMoveQuestionUp={this.props.onMoveQuestionUp}
          onMoveQuestionDown={this.props.onMoveQuestionDown}
        />
      </Collapsible>
    );
  }
}

ElementRow.propTypes = {
  groupedElement: GroupedElementShape.isRequired,
  rowsPerHeader: PropTypes.number,
  onMoveRequirementUp: PropTypes.func.isRequired,
  onMoveRequirementDown: PropTypes.func.isRequired,
  onMoveQuestionUp: PropTypes.func.isRequired,
  onMoveQuestionDown: PropTypes.func.isRequired
};

ElementRow.defaultProps = {
  rowsPerHeader: 0
};

export default ElementRow;
