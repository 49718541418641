import React from "react"
import PropTypes from "prop-types"
import AssessmentRespondentRow from "./AssessmentRespondentRow"
import AssessmentRespondentMessage from "./AssessmentRespondentMessage"

class AssessmentRespondentTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { assessment_respondents: [], editMode: {}, invitationSuccess: false, current_assessment_respondent: null };
  };

  componentWillMount() {
    this.loadRespondents();
  };

  loadRespondents = () => {
    $.getJSON(`/api/assessments/${this.props.assessment_id}/assessment_respondents.json`, (response) => {
      this.setState({ assessment_respondents: response });
    });
  };

  resetForms = () => {
    let forms = {};
    this.state.assessment_respondents.map((assessment_respondent) => {
      forms[assessment_respondent.id] = false;
    });
    this.setState({ editMode: forms });
  };

  setEditMode = (id) => {
    let forms = {};
    this.state.assessment_respondents.map((assessment_respondent) => {
      forms[assessment_respondent.id] = assessment_respondent.id == id;
    })
    this.setState({ editMode: forms });
  };

  setMessageStatus = (assessment_respondent, success) => {
    this.setState({ current_assessment_respondent: assessment_respondent })
    this.setState({ invitationSuccess: success });
  };

  render () {
    let content = <p className="users-table__paragraph">There are no people in this assessment.</p>;

    if(this.state.assessment_respondents.length > 0) {
      content = this.state.assessment_respondents.map((assessment_respondent, index) => {
        return <AssessmentRespondentRow key={ assessment_respondent.id } assessment_respondent={ assessment_respondent } assessment_id= { this.props.assessment_id } assessment_started= { this.props.assessment_started } editMode={ this.state.editMode[assessment_respondent.id] } resetForms={this.resetForms} setEditMode={this.setEditMode} setMessageStatus={this.setMessageStatus} loadRespondents={this.loadRespondents} />;
      });
    }

    return (
            <div className="users-table">
              <div className="users-table__row">
                <div className="users-table__heading users-table__heading--name"><span className="users-table__text">Name</span></div>
                <div className="users-table__heading users-table__heading--role"><span className="users-table__text">Role</span></div>
                <div className="users-table__heading users-table__heading--location"><span className="users-table__text">Location</span></div>
                <div className="users-table__heading users-table__heading--territory"><span className="users-table__text">Region</span></div>
                <div className="users-table__heading users-table__heading--function"><span className="users-table__text">Team</span></div>
                <div className="users-table__heading users-table__heading--email"><span className="users-table__text">Email</span></div>
                <div className="users-table__heading users-table__heading--mobile"><span className="users-table__text">Mobile</span></div>
                <div className="users-table__heading users-table__heading--comms"><span className="users-table__text">Preference</span></div>
                <div className="users-table__heading users-table__heading--resend users-table__heading--end"><span className="users-table__text">Resend</span></div>
              </div>

              <div id="js-respondent-list">
                { content }
              </div>
              <AssessmentRespondentMessage assessment_respondent={ this.state.current_assessment_respondent } success={ this.state.invitationSuccess }/>
            </div>
    );
  }
}

AssessmentRespondentTable.propTypes = {
  assessment_id: PropTypes.number,
  assessment_started: PropTypes.bool
};

export default AssessmentRespondentTable;
