import React from "react"
import PropTypes from "prop-types"
import RespondentView from "./RespondentView"
import RespondentForm from "./RespondentForm"

class RespondentRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: {} };
  };

  handleAdd = () => {
    this.setState({ errors: {} });
    this.props.setEditMode("new");
  };

  handleCancel = () => {
    this.setState({ errors: {} });
    this.props.resetForms();
  };

  handleEdit = () => {
    this.props.setEditMode(this.props.respondent.id);
  };

  handleCreate = (id, respondent) => {
    $.ajax({
      url: "/api/respondents",
      type: "POST",
      data: { respondent: respondent },
      success: response => {
        this.props.loadRespondents();
        this.props.resetForms();
        this.setState({ errors: {} });
      },
      error: (response) => {
        this.setState({ errors: response.responseJSON });
      }
    });
  };

  handleDelete = id => {
    $.ajax({
      url: `/api/respondents/${id}`,
      type: "DELETE",
      success: response => {
        this.props.loadRespondents();
        this.setState({ errors: {} });
        $(".users-table__button--add").focus();
      },
      error: (response) => {
        this.state.errors = response.responseJSON;
      }
    });
  };

  handleUpdate = (id, respondent) => {
    $.ajax({
      url: `/api/respondents/${id}`,
      type: "PATCH",
      data: { respondent: respondent },
      success: response => {
        this.props.loadRespondents();
        this.props.resetForms();
        this.setState({ errors: {} });
      },
      error: (response) => {
        this.setState({ errors: response.responseJSON });
      }
    });
  };

  addValidationError = (attribute, message) => {
    let errors = this.state.errors;
    errors[attribute] = message == "" ? [] : [ message ];
    this.setState({ errors: errors });
  }

  render() {
    let row;
    if(this.props.createForm) {
      if(this.props.editMode) {
        row = <RespondentForm respondent={ null } business_roles={ this.props.business_roles } business_locations={ this.props.business_locations } business_functions={ this.props.business_functions } business_territories={ this.props.business_territories } errors={ this.state.errors } addValidationError={ this.addValidationError } handleCancel={ this.handleCancel } handleConfirm={ this.handleCreate } />;
      }
      else {
        row = <div className="users-table__row">
                <div className="users-table__cell users-table__cell--add">
                  <button className="users-table__button users-table__button--add" onClick={ () => this.handleAdd() }>Add</button>
                </div>
              </div>
      }
    }
    else {
      if(this.props.editMode) {
        row = <RespondentForm respondent={ this.props.respondent } business_roles={ this.props.business_roles } business_locations={ this.props.business_locations }  business_functions={ this.props.business_functions } business_territories={ this.props.business_territories } errors={ this.state.errors } handleCancel={ this.handleCancel } handleConfirm={ this.handleUpdate } addValidationError={ this.addValidationError } />;
      }
      else {
        row = <RespondentView respondent={ this.props.respondent } handleDelete={this.handleDelete} handleEdit={this.handleEdit} />;
      }
    }
    return (
      <div>
        { row }
      </div>
    )
  }
}

RespondentRow.propTypes = {
  business_roles: PropTypes.array,
  business_locations: PropTypes.array,
  business_functions: PropTypes.array,
  business_territories: PropTypes.array,
  editMode: PropTypes.bool,
  createForm: PropTypes.bool,
  respondent: PropTypes.object
};

export default RespondentRow;
