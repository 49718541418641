import * as PropTypes from "prop-types";
import React from "react";
import { ElementShape, QuestionShape, RequirementShape } from "../shapes";
import ElementRow from "./ElementRow/ElementRow";

class OrderManager extends React.Component {
  static propTypes = {
    elements: PropTypes.arrayOf(ElementShape).isRequired,
    requirements: PropTypes.arrayOf(RequirementShape).isRequired,
    questions: PropTypes.arrayOf(QuestionShape).isRequired
  };

  state = {
    elements: this.props.elements,
    requirements: this.props.requirements,
    questions: this.props.questions
  };

  /**
   * Handles when a `Requirement` is moved up.
   */
  handleMoveRequirementUp = ({ requirement }) => this.moveRequirementOnState(requirement, -1);

  /**
   * Handles when a `Requirement` is moved down.
   */
  handleMoveRequirementDown = ({ requirement }) => this.moveRequirementOnState(requirement, +1);

  /**
   * Handles when a `Question` is moved up.
   */
  handleMoveQuestionUp = ({ question }) => this.moveQuestionOnState(question, -1);

  /**
   * Handles when a `Question` is moved down.
   */
  handleMoveQuestionDown = ({ question }) => this.moveQuestionOnState(question, +1);

  /**
   * @inheritDoc
   *
   * @override
   */
  componentDidMount() {
    /*
      Submission is done by mapping each question & requirement into an input,
      with the name being the record type & id, & the value being their position.

      The naming structure should result in a `params` containing two hashes,
      named `questions` & `requirements`, with the key being the record id, like so:

      {
        "questions" => { "1" => "1", "2" => "2", "3" => "1", ... },
        "requirements" => { "1" => "1", "2" => "2", "3" => "3", ... }
      }
     */
    $("#save-order-form").on("submit", event =>
      $(event.target)
        .append(
          ...this.state.requirements.map(requirement =>
            $("<input />")
              .attr("name", `requirements[${requirement.id}]`)
              .attr("value", JSON.stringify({ order_within_parent: requirement.order_within_parent, edited: requirement["edited"]}))
              .attr("type", "hidden")
          ),
          ...this.state.questions.map(question =>
            $("<input />")
              .attr("name", `questions[${question.id}]`)
              .attr("value", JSON.stringify({ order_within_parent: question.order_within_parent, edited: question["edited"]}))
              .attr("type", "hidden")
          )
        )
    );

    /*
      When `#discard-order-button` is clicked, we reset everything by just
      copying the props to the state, since they should be structurally 1:1.
     */
    $("#discard-order-button").on("click", () => this.setState({ ...this.props }));
  }

  /**
   * Gets all the `Question`s that belong to the given `requirement` from the `state`.
   *
   * @param {Object} requirement
   *
   * @return {Array<Object>}
   */
  getQuestionsForRequirementFromState(requirement) {
    const requirementId = requirement.parent_id !== null
      ? requirement.parent_id
      : requirement.id;

    return this.state.questions
               .filter(question => question.requirement_id === requirementId)
               .sort((a, b) => a.order_within_parent - b.order_within_parent);
  }

  /**
   * Gets all the `Requirement`s that belong to the given `element` from the `state`.
   *
   * @param {Object} element
   *
   * @return {Array<Object>}
   */
  getRequirementsForElementFromState(element) {
    const elementId = element.parent_id !== null
      ? element.parent_id
      : element.id;

    return this.state.requirements
               .filter(requirement => requirement.element_id === elementId)
               .sort((a, b) => a.order_within_parent - b.order_within_parent);
  }

  /**
   * Groups the given `requirement` with its `questions` from the `state`.
   *
   * @param {Object} requirement the `Requirement` to group with its `Question`s.
   *
   * @return {Object}
   */
  groupRequirementWithQuestionsFromState(requirement) {
    return {
      requirement,
      questions: this.getQuestionsForRequirementFromState(requirement)
    };
  }

  /**
   * Groups the `requirements` of the given `element` from the `state` with their `questions`.
   *
   * @param {Object} element the `Element` to group `Requirement`s for.
   *
   * @return {Array<Object>}
   */
  groupRequirementsForElementFromState(element) {
    return this.getRequirementsForElementFromState(element)
               .map(requirement => this.groupRequirementWithQuestionsFromState(requirement));
  }

  /**
   * Maps `elements` from the `state` into groups containing the `Element` & their
   * `Requirement`s, in turn grouped with their `Question`s.
   *
   * @return {Array<Object>}
   */
  groupElementsFromState() {
    return this.state.elements.map(element => ({
      element,
      requirements: this.groupRequirementsForElementFromState(element)
    })).sort((a, b) => a.element.order_within_parent - b.element.order_within_parent);
  }

  /**
   * Moves the given `requirement` by the given `amount` in the `requirements` collection on the state.
   *
   * This method causes the recalculation of the `order_within_parent` of *all* `Requirement`s.
   *
   * @param {Object} requirement
   * @param {number} amount
   */
  moveRequirementOnState(requirement, amount) {
    const newPosition = requirement.order_within_parent + amount;

    if (newPosition <= 0) {
      return;
    }

    const {
      requirementsToReorder,
      otherRequirements
    } = this.state.requirements.reduce(
      (acc, aRequirement) => {
        ( // only requirements belonging to the same element get sorted
          aRequirement.element_id === requirement.element_id
            ? acc.requirementsToReorder
            : acc.otherRequirements
        ).push(aRequirement);

        return acc;
      }, // we need to retain the other requirements to concat later
      { requirementsToReorder: [], otherRequirements: [] }
    );

    this.setState({
      requirements: this.reorderOrderableInCollection(
        requirement,
        newPosition,
        requirementsToReorder
      ).concat(otherRequirements)
    });
  }

  /**
   * Moves the given `question` by the given `amount` in the `questions` collection on the state.
   *
   * This method causes the recalculation of the `order_within_parent` of *all* `Question`s.
   *
   * @param {Object} question
   * @param {number} amount
   */
  moveQuestionOnState(question, amount) {
    const newPosition = question.order_within_parent + amount;

    if (newPosition <= 0) {
      return;
    }

    const {
      questionsToReorder,
      otherQuestions
    } = this.state.questions.reduce(
      (acc, aQuestion) => {
        ( // only questions belonging to the same requirement get sorted
          aQuestion.requirement_id === question.requirement_id
            ? acc.questionsToReorder
            : acc.otherQuestions
        ).push(aQuestion);

        return acc;
      }, // we need to retain the other questions to concat later
      { questionsToReorder: [], otherQuestions: [] }
    );

    this.setState({
      questions: this.reorderOrderableInCollection(
        question,
        newPosition,
        questionsToReorder
      ).concat(otherQuestions)
    });
  }

  /**
   * Reorders the given `orderable` to be in the given `position`,
   * recalculating the `order_within_parent` value of all elements in the given `orderables`,
   * to reflect their orders with the `orderable` in its news position.
   *
   * @param {T} orderable
   * @param {number} position
   * @param {Array<T>} orderables
   *
   * @return {Array<T>}
   *
   * @template T
   */
  reorderOrderableInCollection(orderable, position, orderables) {
    orderable["edited"] = true

    const objects = [...orderables].sort((a, b) => a.order_within_parent - b.order_within_parent);

    objects.splice(objects.indexOf(orderable), 1);
    objects.splice(position - 1, 0, orderable);

    return objects.map((orderable, pos) => Object.assign({}, orderable, { order_within_parent: pos + 1 }));
  }

  render() {
    const groupedElements = this.groupElementsFromState();

    return (
      <div>
        {
          groupedElements.map(groupedElement => (
            <ElementRow
              key={groupedElement.element.id}

              groupedElement={groupedElement}

              rowsPerHeader={10}

              onMoveRequirementUp={this.handleMoveRequirementUp}
              onMoveRequirementDown={this.handleMoveRequirementDown}
              onMoveQuestionUp={this.handleMoveQuestionUp}
              onMoveQuestionDown={this.handleMoveQuestionDown}
            />
          ))
        }
      </div>
    );
  }
}

export default OrderManager;
