import BarGraph from "./BarGraph"

class StackedBarGraph extends BarGraph {
  getXLabels(data) {
    var keys = [];
    Object.keys(data).forEach(function(key) {
      var key = JSON.parse(key)[0];
      keys.indexOf(key) === -1 ? keys.push(key) : null;
    });

    return keys;
  };

  getYGroups(data) {
    var keys = [];
    Object.keys(data).forEach(function(key) {
      var key = JSON.parse(key)[1];
      keys.indexOf(key) === -1 ? keys.push(key) : null;
    });
    return keys;
  };

  getDatasets(data) {
    var adminDataStackedBarGraph = this;
    var colours = ["#e0e9f7", "#89ace1", "#214884"]
    var keys = [];
    var datasets = [];
    var yGroups = this.getXLabels(data);
    var xGroups = this.getYGroups(data);

    xGroups.forEach((xGroup, index) => {
      datasets.push({
        label: xGroup,
        backgroundColor: colours[index],
        data: adminDataStackedBarGraph.getDataset(xGroup, yGroups, data)
      });
    });
    return datasets;
  };

  getDataset(xGroup, yGroups, data) {
    var dataset = [];
    yGroups.forEach((yGroup) => {
      dataset.push(data[`["${yGroup}", "${xGroup}"]`]);
    });
    return dataset;
  };

  renderGraph(data) {

    new Chart(this.canvas, {
      type: this.type,
      data: {
        labels: this.getXLabels(data),
        datasets: this.getDatasets(data)
      },
      options: {
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.xAxis,
              fontSize: 16
            },
            stacked: true,
            ticks: {
              min: 0
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.yAxis,
              fontSize: 16
            },
            ticks: {
              min: 0
            },
            stacked: true
          }]
        },
        elements: {
          rectangle: {
            backgroundColor: "#e0e9f7",
            borderColor: "#214884",
            borderWidth: 3
          }
        }
      }
    });
  };
};

export default StackedBarGraph;
