import * as PropTypes from "prop-types";

const DraftableInterfaceShape = {
  draft: PropTypes.bool.isRequired,
  parent_id: PropTypes.number,
  order_within_parent: PropTypes.number.isRequired
};

const TimestampInterfaceShape = {
  created_at: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired
};

export const ElementShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  prefix_statement: PropTypes.string.isRequired,
  ...DraftableInterfaceShape,
  ...TimestampInterfaceShape
});

export const RequirementShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  optional: PropTypes.bool.isRequired,
  element_id: PropTypes.number.isRequired,
  edited: PropTypes.bool,
  ...DraftableInterfaceShape,
  ...TimestampInterfaceShape
});

export const QuestionShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  priority_within_element: PropTypes.number.isRequired,
  requirement_id: PropTypes.number.isRequired,
  edited: PropTypes.bool,
  ...DraftableInterfaceShape,
  ...TimestampInterfaceShape
});

export const GroupedRequirementShape = PropTypes.shape({
  requirement: RequirementShape.isRequired,
  questions: PropTypes.arrayOf(QuestionShape).isRequired
});

export const GroupedElementShape = PropTypes.shape({
  element: ElementShape.isRequired,
  requirements: PropTypes.arrayOf(GroupedRequirementShape).isRequired
});

export const ElementGroupedWithQuestionsShape = PropTypes.shape({
  element: ElementShape.isRequired,
  questions: PropTypes.arrayOf(QuestionShape).isRequired
});
