import cx from "classnames";
import * as PropTypes from "prop-types";
import React from "react";
import { ElementShape } from "./shapes";
import AccordionArrow from "./AccordionArrow";
import ElementRowColumn from "./ElementRowColumn";

class ElementRowDetails extends React.Component {
  static propTypes = {
    element: ElementShape.isRequired,
    open: PropTypes.bool.isRequired
  };

  /**
   * Gets the content to render for the given "column" of this row's `Element`.
   *
   * If no `content` is provided, `columnName` is assumed to be a key for a property
   * on this components `Element` prop, and used as such to get a value.
   *
   * @param {string} columnName
   * @param {string|React.ReactNode} content
   *
   * @return {JSX}
   */
  getElementColumnRenderContent(
    columnName,
    content = this.props.element[columnName]
  ) {
    return (
      <ElementRowColumn
        className={cx(
          `record-row__column--${columnName}`,
          "record-row__column--main"
        )}
      >
        {content}
      </ElementRowColumn>
    );
  }

  /**
   * Creates a dasherised HTML class to identify the element
   *
   * @return {string}
   */
  getElementRowClass() {
    return `record-row--${this.props.element.name
      .replace(/\s+/g, "-")
      .toLowerCase()}`;
  }

  render() {
    // Element Name, the Element Display Order number.
    return (
      <div
        className={cx(
          "record-row",
          "record-row--spaced",
          "record-row--element",
          this.getElementRowClass()
        )}
      >
        {this.getElementColumnRenderContent("name")}
        {this.getElementColumnRenderContent("order_within_parent")}
        <AccordionArrow
          className={cx(
            "record-row__column",
            "record-row__column--expansion-arrow",
            "record-row__column--main"
          )}
          pointUp={this.props.open}
          colour="white"
        />
      </div>
    );
  }
}

export default ElementRowDetails;
