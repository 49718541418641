import React from "react"
import PropTypes from "prop-types"
import AssessmentRespondentView from "./AssessmentRespondentView"
import AssessmentRespondentForm from "./AssessmentRespondentForm"

class AssessmentRespondentRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = { errors: {} };
  };

  handleCancel = () => {
    this.setState({ errors: {} });
    this.props.resetForms();
  };

  handleEdit = () => {
    this.props.setEditMode(this.props.assessment_respondent.id);
  };

  handleUpdate = (id, assessment_respondent, send = false) => {
    this.updateAssessmentRespondent(id, assessment_respondent)
      .done(() => {
        if (send) {
          this.sendAssessmentInvitation(id);
        }
      })
      .done(() => {
        this.props.loadRespondents();
        this.props.resetForms();
        this.setState({ errors: {} });
        this.props.setMessageStatus(assessment_respondent, true);
      })
      .fail((response, status, error) => {
        this.setState({ errors: response.responseJSON });
        this.props.setMessageStatus(assessment_respondent, false);
      });
  };

  handleUpdateAndSend = (id, assessment_respondent) => {
    this.handleUpdate(id, assessment_respondent, true)
  };

  updateAssessmentRespondent = (id, assessment_respondent) => {
    return $.ajax({
      url: `/api/assessments/${this.props.assessment_id}/assessment_respondents/${id}`,
      type: "PATCH",
      data: { assessment_respondent: assessment_respondent },
    });
  };

  sendAssessmentInvitation = (id) => {
    return $.ajax({
      url: "/api/assessment_invitations",
      type: "POST",
      data: { assessment_respondent_id: id },
    });
  };

  addValidationError = (attribute, message) => {
    let errors = this.state.errors;
    errors[attribute] = message == "" ? [] : [ message ];
    this.setState({ errors: errors });
  }

  render() {
    let row;
    if(this.props.editMode) {
      row = <AssessmentRespondentForm assessment_respondent={ this.props.assessment_respondent } assessment_started= { this.props.assessment_started } errors={ this.state.errors } handleCancel={ this.handleCancel } handleConfirm={ this.handleUpdate } handleConfirmAndSend={ this.handleUpdateAndSend } addValidationError={ this.addValidationError } />;
    }
    else {
      row = <AssessmentRespondentView assessment_respondent={ this.props.assessment_respondent } handleEdit={this.handleEdit} />;
    }

    return (
      <div>
        { row }
      </div>
    )
  }
}

AssessmentRespondentRow.propTypes = {
  editMode: PropTypes.bool,
  assessment_respondent: PropTypes.object,
  assessment_id: PropTypes.number,
  assessment_started: PropTypes.bool
};

export default AssessmentRespondentRow;
