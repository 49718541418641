import cx from "classnames";
import * as PropTypes from "prop-types";
import React from "react";
import { QuestionShape } from "../../shapes";
import OrderControls from "../../OrderControls";
import QuestionRowColumn from "../QuestionRowColumn/QuestionRowColumn";

class QuestionRow extends React.Component {
  static propTypes = {
    question: QuestionShape.isRequired,
    hideOrderingControls: PropTypes.bool,
    onMoveQuestionUp: PropTypes.func.isRequired,
    onMoveQuestionDown: PropTypes.func.isRequired
  };

  static defaultProps = {
    hideOrderingControls: false
  };

  /**
   * Handles when the up arrow on the `OrderControls` component is clicked.
   */
  handleMoveUpArrowClick = () =>
    this.props.onMoveQuestionUp({ question: this.props.question });

  /**
   * Handles when the down arrow on the `OrderControls` component is clicked.
   */
  handleMoveDownArrowClick = () =>
    this.props.onMoveQuestionDown({ question: this.props.question });

  /**
   * Gets the `text` of this components `Question`'s `most_senior_statement`,
   * if it has one.
   *
   * @return {string}
   */
  getMostSeniorStatementText() {
    const mostSeniorStatement = this.props.question.most_senior_statement;

    if (mostSeniorStatement === null) {
      return "------";
    }

    return mostSeniorStatement.text;
  }

  /**
   * Gets the content to render for the order controls section of this component.
   *
   * If `hideOrderingControls` is `true`, then an empty `QuestionRowColumn` is returned.
   * Otherwise, an `OrderControls` component is returned.
   *
   * @return {JSX}
   */
  getOrderControlsColumnRenderContent() {
    if (this.props.hideOrderingControls) {
      return (
        <QuestionRowColumn className={"record-row__column--order-controls"} />
      );
    }

    return (
      <OrderControls
        className={cx(
          "record-row__column",
          "record-row__column--order-controls"
        )}
        onMoveUpArrowClick={this.handleMoveUpArrowClick}
        onMoveDownArrowClick={this.handleMoveDownArrowClick}
      />
    );
  }

  /**
   * Gets the content to render for the given "column" of this rows `Question`.
   *
   * If no `content` is provided, `columnName` is assumed to be a key for a property
   * on this components `Question` prop, and used as such to get a value.
   *
   * @param {string} columnName
   * @param {string|React.ReactNode} content
   *
   * @return {JSX}
   */
  getQuestionColumnRenderContent(
    columnName,
    content = this.props.question[columnName]
  ) {
    return (
      <QuestionRowColumn className={`record-row__column--${columnName}`}>
        {content}
      </QuestionRowColumn>
    );
  }

  render() {
    //  * property order: up/down arrow, display order within parent, code, most senior role statement, priority within element, record status
    return (
      <div className={cx("record-row", "record-row--element")}>
        {this.getOrderControlsColumnRenderContent()}
        {this.getQuestionColumnRenderContent("priority_within_element")}
        {this.getQuestionColumnRenderContent("code")}
        {this.getQuestionColumnRenderContent(
          "most_senior_statement",
          this.getMostSeniorStatementText()
        )}
        {this.getQuestionColumnRenderContent("status")}
      </div>
    );
  }
}

export default QuestionRow;
