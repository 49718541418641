import cx from "classnames";
import * as PropTypes from "prop-types";
import React from "react";
import { RequirementShape } from "../../shapes";
import AccordionArrow from "../../AccordionArrow";
import OrderControls from "../../OrderControls";
import RequirementRowColumn from "../RequirementRowColumn/RequirementRowColumn";

class RequirementRowDetails extends React.Component {
  static propTypes = {
    requirement: RequirementShape.isRequired,
    numberOfQuestions: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,

    onMoveRequirementUp: PropTypes.func.isRequired,
    onMoveRequirementDown: PropTypes.func.isRequired
  };

  /**
   * Handles when the up arrow on the `OrderControls` component is clicked.
   *
   * @param {React.SyntheticEvent} event
   */
  handleMoveUpArrowClick = event => {
    event.stopPropagation();

    this.props.onMoveRequirementUp({ requirement: this.props.requirement });
  };

  /**
   * Handles when the down arrow on the `OrderControls` component is clicked.
   *
   * @param {React.SyntheticEvent} event
   */
  handleMoveDownArrowClick = event => {
    event.stopPropagation();

    this.props.onMoveRequirementDown({ requirement: this.props.requirement });
  };

  /**
   * Gets the `text` representation of this components `Requirement`'s `optional` property.
   *
   * @return {string}
   */
  getOptionalPropertyText() {
    return this.props.requirement.optional ? "Y" : "N";
  }

  /**
   * Gets the content to render for the given "column" of this row's `Requirement`.
   *
   * If no `content` is provided, `columnName` is assumed to be a key for a property
   * on this components `Requirement` prop, and used as such to get a value.
   *
   * @param {string} columnName
   * @param {string|React.ReactNode} content
   *
   * @return {JSX}
   */
  getRequirementColumnRenderContent(
    columnName,
    content = this.props.requirement[columnName]
  ) {
    return (
      <RequirementRowColumn className={`record-row__column--${columnName}`}>
        {content}
      </RequirementRowColumn>
    );
  }

  render() {
    return (
      <div className={cx("record-row", "record-row--requirement")}>
        <OrderControls
          className={cx(
            "record-row__column",
            "record-row__column--order-controls"
          )}
          onMoveUpArrowClick={this.handleMoveUpArrowClick}
          onMoveDownArrowClick={this.handleMoveDownArrowClick}
        />
        {this.getRequirementColumnRenderContent("code")}
        {this.getRequirementColumnRenderContent("name")}
        {this.getRequirementColumnRenderContent(
          "optional",
          this.getOptionalPropertyText()
        )}
        {this.getRequirementColumnRenderContent("status")}
        <RequirementRowColumn className={"record-row__column--questions-count"}>
          {this.props.numberOfQuestions}
        </RequirementRowColumn>
        <AccordionArrow
          className={cx(
            "record-row__column",
            "record-row__column--expansion-arrow"
          )}
          pointUp={this.props.open}
          colour="black"
        />
      </div>
    );
  }
}

export default RequirementRowDetails;
