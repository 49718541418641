import React from "react"
import PropTypes from "prop-types"

class AssessmentRespondentView extends React.Component {

  constructor(props) {
    super(props);
    this.state = { editable: false };
  };

  handleEdit = () => {
    this.props.handleEdit();
  };

  render() {
    const { name: fullName, email, mobile, preferred_comms_method_display_value, preferred_comms_method, id, business_role: { name: roleName } } = this.props.assessment_respondent;
    return (
      <div className="users-table__row">
        <div className="users-table__cell users-table__cell--name">
          <span className="users-table__label">Name</span><span className="users-table__text">{ fullName }</span>
        </div>
        <div className="users-table__cell users-table__cell--role">
          <span className="users-table__label">Role</span><span className="users-table__text">{ roleName }</span>
        </div>
        <div className="users-table__cell users-table__cell--location">
          <span className="users-table__label">Location</span><span className="users-table__text">{ this.props.assessment_respondent.business_location ? this.props.assessment_respondent.business_location.name : "" }</span>
        </div>
        <div className="users-table__cell users-table__cell--territory">
          <span className="users-table__label">Region</span><span className="users-table__text">{ this.props.assessment_respondent.business_territory ? this.props.assessment_respondent.business_territory.name : "" }</span>
        </div>
        <div className="users-table__cell users-table__cell--function">
          <span className="users-table__label">Team</span><span className="users-table__text">{ this.props.assessment_respondent.business_function ? this.props.assessment_respondent.business_function.name : "" }</span>
        </div>
        <div className="users-table__cell  users-table__cell--email">
          <span className="users-table__label">Email</span><span className="users-table__text">{ email }</span>
        </div>
        <div className="users-table__cell users-table__cell--mobile">
          <span className="users-table__label">Mobile</span><span className="users-table__text">{ mobile }</span>
        </div>
        <div className="users-table__cell users-table__cell--comms">
          <span className="users-table__label">Preferred Comms</span><span className={ `users-table__text users-table__text--${ preferred_comms_method }` }>{ preferred_comms_method_display_value }</span>
        </div>
        <div className="users-table__cell users-table__cell--resend">
          <button className="users-table__button users-table__button--resend" onClick={ (e) => this.handleEdit(e) }>Resend</button>
        </div>
      </div>
    )
  }
}

export default AssessmentRespondentView;
