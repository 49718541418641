import * as PropTypes from "prop-types";
import React from "react";
import { QuestionShape } from "../../shapes";
import QuestionHeader from "../QuestionHeader/QuestionHeader";
import QuestionRow from "../QuestionRow/QuestionRow";

class RequirementRowQuestions extends React.Component {
  static propTypes = {
    questions: PropTypes.arrayOf(QuestionShape).isRequired,
    rowsPerHeader: PropTypes.number,

    onMoveQuestionUp: PropTypes.func.isRequired,
    onMoveQuestionDown: PropTypes.func.isRequired
  };

  static defaultProps = {
    rowsPerHeader: 0
  };

  /**
   * Checks if a `QuestionHeader` component should be added to the list of `QuestionRow`s,
   * based on the index of the current `Question` being rendered, compared with the value of `rowsPerHeader`.
   *
   * @param {number} index
   *
   * @return {boolean}
   */
  shouldAddQuestionHeaderRow(index) {
    if (this.props.rowsPerHeader === false) {
      return false;
    }

    if (index === 0) {
      return true;
    }

    return index % this.props.rowsPerHeader === 0;
  }

  render() {
    return (
      <div className="record-row__questions">
        {
          this.props.questions.map((question, index) => [
            this.shouldAddQuestionHeaderRow(index) && <QuestionHeader key={`header-${index}`} />,
            <QuestionRow
              key={question.id}

              question={question}
              hideOrderingControls={this.props.questions.length === 1}

              onMoveQuestionUp={this.props.onMoveQuestionUp}
              onMoveQuestionDown={this.props.onMoveQuestionDown}
            />
          ])
        }
      </div>
    );
  }
}

export default RequirementRowQuestions;
