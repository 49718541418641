import cx from "classnames";
import React from "react";
import QuestionRowColumn from "../QuestionRowColumn/QuestionRowColumn";

/**
 * Gets the content to render for the given "column" of this rows `Question`.
 *
 * @param {string} columnName
 * @param {string} content
 *
 * @return {JSX}
 *
 * @instance
 */
const getQuestionColumnRenderContent = (columnName, content) => (
  <QuestionRowColumn className={`record-row__column--${columnName}`}>
    {content}
  </QuestionRowColumn>
);

const QuestionHeader = () => (
  <div className={cx("record-row", "record-row__header")}>
    <QuestionRowColumn className={"record-row__column--order-controls"} />
    {getQuestionColumnRenderContent("priority_within_element", "Priority within element")}
    {getQuestionColumnRenderContent("code", "Code")}
    {getQuestionColumnRenderContent("most_senior_statement", "Most senior statement")}
    {getQuestionColumnRenderContent("status", "Status")}
  </div>
);

export default QuestionHeader;
