import React from "react"
import PropTypes from "prop-types"

class RespondentForm extends React.Component {

  constructor(props) {
    super(props);

    if(props.respondent) {
      this.state = {
        id: props.respondent.id,
        name: props.respondent.name,
        email: props.respondent.email,
        mobile: props.respondent.mobile,
        business_role_id: props.respondent.business_role_id,
        business_location_id: props.respondent.business_location_id,
        business_function_id: props.respondent.business_function_id,
        business_territory_id: props.respondent.business_territory_id,
        preferred_comms_method: props.respondent.preferred_comms_method
      };
    }
    else {
      this.state = {
        id: "",
        name: "",
        email: "",
        mobile: "",
        business_role_id: "",
        business_location_id: "",
        business_function_id: "",
        business_territory_id: "",
        preferred_comms_method: "prefers_email_comms"
      };
    }
  };

  componentDidMount() {
    this.initialiseSelect(this.roleSelect, true);
    this.initialiseSelect(this.locationSelect, false);
    this.initialiseSelect(this.territorySelect, false);
    this.initialiseSelect(this.functionSelect, false);

    $("#name").focus()[0].scrollIntoView({block: "center"});
  }

  handleSave = id => {
    var data = {
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
      business_role_id: this.state.business_role_id,
      business_location_id: this.state.business_location_id,
      business_function_id: this.state.business_function_id,
      business_territory_id: this.state.business_territory_id,
      preferred_comms_method: this.preferredCommsMethodValue()
    };

    this.props.handleConfirm(id, data);
  };

  handleCancel() {
    this.props.handleCancel();
  };

  handleInputChange = (e) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [e.target.name]: value
    });
  };

  initialiseSelect = (element, required) => {
    let options;
    if(required) {
      $(element).selectize(window.Globals.requiredSelectizeOptions);
    }
    else {
      $(element).selectize(window.Globals.optionalSelectizeOptions);
    }
  };

  validateInput = (element) => {
    const value = element.value;
    const attribute = element.name;

    this.setState({
      [element.name]: value
    });

    let valid = true;
    let message = "";

    switch(attribute) {
      case "name":
        valid = value.length > 0;
        message = valid ? '' : "Please enter this person's name";
        break;
      case "email":
        valid = value.length == 0 || value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        message = valid ? '' : "Please enter a valid email address";
        break;
      case "business_role_id":
        valid = value.length > 0;
        message = valid ? '': "Please assign this person a role";
        break;
      case "mobile":
        valid = value.length == 0 || value.match(/^(\+64|0064|0)2\d{7,9}$/i);
        message = valid ? '': "A mobile number must start with 02, +642, or 00642, and be 7-9 numbers except for 02, +642, 00642 with no spaces or dashes";
        break;
      default:
        break;
    }

    this.props.addValidationError(attribute, message);
  }

  renderInputError = (attribute) => {
    let error;
    if(this.inputErrorPresent(attribute)) {
      error = this.props.errors[attribute][this.props.errors[attribute].length - 1];
      return(
        <label className={ `message form__error ${ attribute }` } htmlFor="name">
          { error }
        </label>
      );
    }
  }

  inputErrorPresent = (attribute) => {
    return (
      this.props.errors[attribute] && this.props.errors[attribute].length
    )
  }

  renderBusinessLocationSelect = (businessLocations, businessLocationId) => {
    if(businessLocations.length > 0) {
      return <div className="form__field">
          <label htmlFor="business_location_id" className="form__label form__label--small">Location</label>
          <span className="form__help">(optional)</span>
          <select id="business_location_id" name="business_location_id" className="form__select" defaultValue={ businessLocationId } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} ref={ (select) => { this.locationSelect = select; } } >
            <option value=""></option>
            {
              businessLocations.map(function(businessLocation) {
                return <option key={businessLocation.id} value={businessLocation.id}>{businessLocation.name}</option>;
              })
            }
          </select>
        </div>
    }
  };

  renderBusinessFunctionSelect = (businessFunctions, businessFunctionId) => {
    if(businessFunctions.length > 0) {
      return <div className="form__field">
          <label htmlFor="business_function_id" className="form__label form__label--small">Team</label>
          <span className="form__help">(optional)</span>
          <select id="business_function_id" name="business_function_id" className="form__select" defaultValue={ businessFunctionId } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} ref={ (select) => { this.functionSelect = select; } } >
            <option value=""></option>
            {
              businessFunctions.map(function(businessFunction) {
                return <option key={businessFunction.id} value={businessFunction.id}>{businessFunction.name}</option>;
              })
            }
          </select>
        </div>
    }
  };

  renderBusinessTerritorySelect = (businessTerritories, businessTerritoryId) => {
    if(businessTerritories.length > 0) {
      return <div className="form__field">
          <label htmlFor="business_territory_id" className="form__label form__label--small">Region</label>
          <span className="form__help">(optional)</span>
          <select id="business_territory_id" name="business_territory_id" className="form__select" defaultValue={ businessTerritoryId } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} ref={ (select) => { this.territorySelect = select; } } >
            <option value=""></option>
            {
              businessTerritories.map(function(businessTerritory) {
                return <option key={businessTerritory.id} value={businessTerritory.id}>{businessTerritory.name}</option>;
              })
            }
          </select>
        </div>
    }
  };

  renderPreferredCommsMethod = (email, mobile, preferredCommsMethod) => {
    const canHavePreference = email && email.length > 0 && mobile && mobile.length > 0;

    if(canHavePreference) {
      return <div className="form__field">
        <div className="radio__group">
          <label htmlFor="preferred_comms_method" className="form__label form__label--small">Preferred Comms</label>
          <div className="radio__control">
            <input type="radio" name="preferred_comms_method" id="preferred_comms_method_email" className="radio__input" value="email" defaultChecked={ preferredCommsMethod == "prefers_email_comms" } onChange={this.handleInputChange} />
            <label htmlFor="preferred_comms_method_email" className="radio__label">Email</label>
          </div>
          <div className="radio__control">
            <input type="radio" name="preferred_comms_method" id="preferred_comms_method_mobile" className="radio__input" value="mobile" defaultChecked={ preferredCommsMethod == "prefers_mobile_comms" } onChange={this.handleInputChange} />
            <label htmlFor="preferred_comms_method_mobile" className="radio__label">Mobile</label>
          </div>
        </div>
      </div>
    }
  };

  preferredCommsMethodValue() {
    if(this.state.email && this.state.mobile) {
      return this.state.preferred_comms_method;
    }
    else {
      return this.state.email ? "email" : "mobile";
    }
  };

  render() {

    return (
      <div className="users-table__form">
        <div className="users-table__form-section form__section--limited">

          <div className="form__field">
            <label htmlFor="name" className="form__label form__label--small">Name</label>
            <input type="text" id="name" name="name" autoFocus="true" placeholder="e.g. Adam Smith" maxLength="80" className="form__input" data-error={this.inputErrorPresent("name")} defaultValue={ this.state.name } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} />
            { this.renderInputError("name") }
          </div>

          <div className="form__field">
            <label htmlFor="role" className="form__label form__label--small">Role</label>
            <select id="business_role_id" name="business_role_id" className="form__select" data-error={this.inputErrorPresent("business_role_id")} defaultValue={ this.state.business_role_id } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} ref={ (select) => { this.roleSelect = select; } } >
              <option value=""></option>
              {
                this.props.business_roles.map(function(business_role) {
                  return <option key={business_role.id} value={business_role.id}>{business_role.name}</option>;
                })
              }
            </select>
            { this.renderInputError("business_role_id") }
          </div>

          { this.renderBusinessLocationSelect(this.props.business_locations, this.state.business_location_id) }

          { this.renderBusinessFunctionSelect(this.props.business_functions, this.state.business_function_id) }

          { this.renderBusinessTerritorySelect(this.props.business_territories, this.state.business_territory_id) }

          <div className="form__field">
            <label htmlFor="email" className="form__label form__label--small">Email</label>
            <input type="email" id="email" name="email" className="form__input" data-error={this.inputErrorPresent("email")} defaultValue={ this.state.email } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} placeholder="e.g. admin@betabuilding.co.nz" maxLength="254" />
            { this.renderInputError("email") }
          </div>

          <div className="form__field">
            <label htmlFor="mobile" className="form__label form__label--small">Mobile</label>
            <p>If this person doesn’t have access to an email address, we can send a link to their smartphone.</p>
            <input type="text" id="mobile" name="mobile" className="form__input" data-error={this.inputErrorPresent("mobile")} defaultValue={ this.state.mobile } onChange={this.handleInputChange} onBlur={(e) => this.validateInput(e.target)} placeholder="e.g. 02123456789, +642123456789, or 00642123456789" maxLength="14" />
            { this.renderInputError("mobile") }
          </div>

          { this.renderPreferredCommsMethod(this.state.email, this.state.mobile, this.state.preferred_comms_method) }

          { this.renderInputError("base") }
        </div>
        <div className="form__section form__section--actions">
          <div className="form__button-wrapper">
            <button className="test-cancel-button form__button form__button--cancel form__button--small" onClick={ () => this.handleCancel() }>Cancel</button>
          </div>
          <div className="form__button-wrapper">
            <button className="form__button form__button--submit form__button--black form__button--small" onClick={ () => this.handleSave(this.state.id) }>Save</button>
          </div>
        </div>
      </div>
    )
  }
}

RespondentForm.propTypes = {
  business_roles: PropTypes.array,
  business_locations: PropTypes.array,
  business_functions: PropTypes.array,
  business_territories: PropTypes.array,
  errors: PropTypes.object
};

export default RespondentForm;
