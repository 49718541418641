import React from "react"
import PropTypes from "prop-types"

class AssessmentRespondentMessage extends React.Component {
  render() {
    let message = "";
    if(this.props.assessment_respondent && this.props.success) {
      const { name, email, mobile, preferred_comms_method } = this.props.assessment_respondent;
      let address;
      let method;
      if(preferred_comms_method == "prefers_email_comms" || preferred_comms_method == "email") {
        address = email;
        method = "email"
      }
      else {
        address = mobile;
        method = "SMS"
      }
      message = `${ name } has been sent an invitation via ${ method } to ${ address}.`;
    }
    return (
      <p className="users-table__message">{ message }</p>
    )
  }
}

AssessmentRespondentMessage.propTypes = {
  success: PropTypes.bool,
  assessment_respondent: PropTypes.object
};

export default AssessmentRespondentMessage;
