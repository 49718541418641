class BarGraph {
  constructor(canvas, endPoint, xAxis, yAxis, oversized, horizontal) {
    if (!canvas) { return; }

    this.canvas = canvas;
    this.xAxis = xAxis;
    this.yAxis = yAxis;
    this.oversized = !oversized;
    this.type = horizontal ? "horizontalBar" : "bar";

    fetch(endPoint, { credentials: "same-origin", dataType: "JSON" })
      .then(response => response.json())
      .then(data => this.renderGraph(data))
      .catch(error => this.displayError(error));
  };

  displayError(response) {
    this.canvas.replaceWith(response.responseText);
  };

  renderGraph(data) {
    new Chart(this.canvas, {
      type: this.type,
      data: {
        labels: Object.keys(data),
        datasets: [{
            data: Object.values(data)
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: this.oversized,
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.xAxis,
              fontSize: 16
            },
            ticks: {
              min: 0
            }
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: this.yAxis,
              fontSize: 16
            },
            ticks: {
              min: 0
            }
          }]
        },
        legend: {
          display: false
        },
        elements: {
          rectangle: {
            backgroundColor: "#e0e9f7",
            borderColor: "#214884",
            borderWidth: 3
          }
        }
      }
    });
  };
};

export default BarGraph;
